<template>
    <main class="p-5 mt-2">
        <div class="top-header flex justify-between items-center align-items-center">
            <div class="text-xl font-semibold">{{ $t('overview')}}</div>
        </div>
        <div class="mt-5">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="flex flex-col graph-1 shadow-sm px-5 py-3 rounded-md shadow-sm">
                    <div class="flex text-gray-400 mb-5">
                        <span class="text-gray-800 text-sm font-semibold">{{ $t('turnover') }}</span>
                    </div>
                    <div class="flex justify-between align-items-center space-x-4">
                        <div class="flex flex-col w-1/2">
                            <div class="text-xs text-gray-400 py-2">{{ $t('thisMonth') }}</div>
                            <div class="font-semibold text-2xl text-gray-900 py-2">30,800</div>
                        </div>
                        <div class="flex justify-center w-1/2">
                            <div class="">
                                <img src="./../../assets/images/dash/graph.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col graph-2 shadow-sm px-5 py-3 rounded-md shadow-sm">
                    <div class="flex text-gray-400 mb-5">
                        <span class="text-gray-800 text-sm font-semibold">{{ $t('grossProfit') }}</span>
                    </div>
                    <div class="flex justify-between align-items-center space-x-4">
                        <div class="flex flex-col w-1/2">
                            <div class="text-xs text-gray-400 py-2">{{ $t('thisMonth') }}</div>
                            <div class="font-semibold text-2xl text-gray-900 py-2">50,700</div>
                        </div>
                        <div class="flex justify-center w-1/2">
                            <div class="">
                                <img src="./../../assets/images/dash/graph.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col graph-3 shadow-sm px-5 py-3 rounded-md shadow-sm">
                    <div class="flex text-gray-400 mb-5">
                        <span class="text-gray-800 text-sm font-semibold">{{ $t('grossProfit') }}</span>
                    </div>
                    <div class="flex justify-between align-items-center space-x-4">
                        <div class="flex flex-col w-1/2">
                            <div class="text-xs text-gray-400 py-2">{{ $t('thisWeek') }}</div>
                            <div class="font-semibold text-2xl text-gray-900 py-2">47,410</div>
                        </div>
                        <div class="flex justify-center w-1/2">
                            <div class="">
                                <img src="./../../assets/images/dash/graph.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="md:col-span-2 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">{{ $t('saleStatistics') }}</h2>
                        <div class="flex justify-between">
                            <!-- <span>sdfs</span>
                            <span>sdfs</span> -->
                        </div>
                    </div>
                    <div class="flex flex-col p-5 w-full">
                        <apexchart height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </div>
                <div class="flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">{{ $t('topCountires') }}</h2>
                    </div>
                    <div class="flex flex-col p-2 w-full h-80 mb-10 overflow-y-scroll">
                        <div class="flex justify-center w-full" v-for="i in 20" :key="i">
                            <div class="flex space-x-3 items-center w-full p-2">
                                <span :class="i % 2 === 0 ? 'fi-se':'fi-gb'" class="h-10 w-10 fi text-2xl rounded-full"></span>
                                <span class="flex flex-col">
                                    <span class="text-sm">Tom Jones</span>
                                    <span class="text-xs text-gray-400">{{ i % 2 === 0 ? 'Person' : 'Organization'}}</span>
                                </span>
                            </div>
                            <span class="text-gray-800 font-semibold p-2">$1,850.00</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-10">
            <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div class="col-span-3 flex flex-col items-center align-items-center space-x-4 border shadow-sm rounded-md shadow-sm overflow-hidden">
                    <div class="flex justify-between w-full box-header p-2 border-b">
                        <h2 class="text-sm">{{ $t('latestOrders') }}</h2>
                        <div class="flex justify-between">
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <table class="table table-auto w-full" border="4">
                            <thead>
                                <tr>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Products</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Order ID</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Date</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Customer Name</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Status</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Amount</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Profit Amount</span></th>
                                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Profit Margin</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="i in 7" :key="i">
                                    <td><span class="text-xs text-gray-700">MacBook Pro</span></td>
                                    <td><span class="text-xs text-gray-700">#349687</span></td>
                                    <td><span class="text-xs text-gray-700">2022-08-10</span></td>
                                    <td><span class="text-xs text-gray-700">Mike Jonatan</span></td>
                                    <td><span class="text-xs text-white bg-green-500 rounded-md p-1 font-semibold">Delivered</span></td>
                                    <td><span class="text-xs text-gray-700">$400</span></td>
                                    <td><span class="text-xs text-gray-700">$16</span></td>
                                    <td>
                                        <span class="text-blue-600 text-xs">4.56%</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    const VueApexCharts = defineAsyncComponent(() =>
        import('vue3-apexcharts')
    )
    import "/node_modules/flag-icons/css/flag-icons.min.css"

    export default {
        name : 'Dashboard',
        components : { 
            apexchart: VueApexCharts,
         },
        data () {
            return {
                chartOptions : {
                    chart: {
                        type: 'bar',
                        stacked: true,
                        height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                    },
                    yaxis: {
                        title: {
                            text: '$ (thousands)'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            return "$ " + val + " thousands"
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Net Profit',
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                    }, 
                    {
                        name: 'Revenue',
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                    }, 
                    
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
.graph-1 {
    background: #EBFDEF;
}
.graph-2 {
    background: #E8EFF9;
}
.graph-3 {
    background: #FFEFE7;
}
.box-header {
    background: #F4F8FF;
}
.table th {
    padding: .3rem;
    vertical-align: top;
    // border-top: 1px solid #dee2e6;
}
.table tbody, td {
    padding: .3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6 !important;
}
</style>